@font-face
	font-family: "svgfont"
	src: url('./../fonts/svgfont.woff2') format('woff2'), url('./../fonts/svgfont.woff') format('woff'), url('./../fonts/svgfont.eot'), url('./../fonts/svgfont.eot?#iefix') format('embedded-opentype'), url('./../fonts/svgfont.ttf') format('truetype')
	font-weight: normal
	font-style: normal

$icon-reset: "\EA01"
$icon-arrow_down: "\EA02"
$icon-slider-prev: "\EA03"
$icon-slider-next: "\EA04"
$icon-calendar: "\EA05"
$icon-cart: "\EA06"
$icon-clock: "\EA07"
$icon-close: "\EA08"
$icon-magnifier: "\EA09"
$icon-lock: "\EA0A"
$icon-log_in: "\EA0B"
$icon-marker: "\EA0C"
$icon-play_button: "\EA0D"
$icon-price: "\EA0E"
$icon-search: "\EA0F"
$icon-eye-off: "\EA10"
$icon-eye: "\EA11"
$icon-user: "\EA12"
$icon-archive: "\EA13"
$icon-share_link: "\EA14"
$icon-facebook_share: "\EA15"
$icon-linkedin_share: "\EA16"
$icon-twitter_share: "\EA17"
$icon-round: "\EA18"
$icon-arrow_right: "\EA19"
$icon-share: "\EA20"
$icon-tm_share: "\EA21"
$icon-fb_share: "\EA22"
$icon-vr_share: "\EA23"
$icon-wa_share: "\EA24"
$icon-letter: "\EA25"
$icon-marker_group: "\EA26"
$icon-phone: "\EA27"
$icon-star: "\EA28"
$icon-percent: "\EA29"
$icon-info: "\EA30"
$icon-hourglass: "\EA31"
$icon-gift: "\EA32"
$icon-edit: "\EA33"
$icon-order: "\EA34"
$icon-house: "\EA35"
$icon-ticket: "\EA36"
$icon-marker2: "\EA37"
$icon-user2: "\EA38"
$icon-round2: "\EA39"
$icon-round3: "\EA40"
$icon-promocodes: "\EA41"
$icon-circle-up: "\EA42"
$icon-telegram_share: "\EA43"
$icon-whatsapp_share: "\EA44"
$icon-action: "\EA45"
$icon-house-hall: "\EA46"
$icon-doors: "\EA47"
$icon-stock: "\EA48"
$icon-date: "\EA49"


%icon
	font-family: "svgfont"
	font-style: normal
	font-weight: normal
	text-rendering: auto
	speak: none
	line-height: 1
	-webkit-font-smoothing: antialiased
	-moz-osx-font-smoothing: grayscale

.icon, [class^="icon-"], [class*=" icon-"]
	@extend %icon
	display: inline-block

.icon-reset:before
	content: $icon-reset
.icon-arrow_down:before
	content: $icon-arrow_down
.icon-slider-prev:before
	content: $icon-slider-prev
.icon-slider-next:before
	content: $icon-slider-next
.icon-calendar:before
	content: $icon-calendar
.icon-cart:before
	content: $icon-cart
.icon-clock:before
	content: $icon-clock
.icon-close:before
	content: $icon-close
.icon-magnifier:before
	content: $icon-magnifier
.icon-lock:before
	content: $icon-lock
.icon-log_in:before
	content: $icon-log_in
.icon-marker:before
	content: $icon-marker
.icon-play_button:before
	content: $icon-play_button
.icon-price:before
	content: $icon-price
.icon-search:before
	content: $icon-search
.icon-eye-off:before
	content: $icon-eye-off
.icon-eye:before
	content: $icon-eye
.icon-user:before
	content: $icon-user
.icon-archive:before
	content: $icon-archive
.icon-share_link:before
	content: $icon-share_link
.icon-facebook_share:before
	content: $icon-facebook_share
.icon-linkedin_share:before
	content: $icon-linkedin_share
.icon-twitter_share:before
	content: $icon-twitter_share
.icon-round:before
	content: $icon-round
.icon-arrow_right:before
	content: $icon-arrow_right
.icon-share:before
	content: $icon-share
.icon-tm_share:before
	content: $icon-tm_share
.icon-fb_share:before
	content: $icon-fb_share
.icon-vr_share:before
	content: $icon-vr_share
.icon-wa_share:before
	content: $icon-wa_share
.icon-letter:before
	content: $icon-letter
.icon-marker_group:before
	content: $icon-marker_group
.icon-phone:before
	content: $icon-phone
.icon-star:before
	content: $icon-star
.icon-percent:before
	content: $icon-percent
.icon-info:before
	content: $icon-info
.icon-hourglass:before
	content: $icon-hourglass
.icon-gift:before
	content: $icon-gift
.icon-edit:before
	content: $icon-edit
.icon-order:before
	content: $icon-order
.icon-house:before
	content: $icon-house
.icon-ticket:before
	content: $icon-ticket
.icon-marker2:before
	content: $icon-marker2
.icon-user2:before
	content: $icon-user2
.icon-round2:before
	content: $icon-round2
.icon-round3:before
	content: $icon-round3
.icon-promocodes:before
	content: $icon-promocodes
.icon-circle-up:before
	content: $icon-circle-up
.icon-telegram_share:before
	content: $icon-telegram_share
.icon-whatsapp_share:before
	content: $icon-whatsapp_share
.icon-action:before
	content: $icon-action
.icon-house-hall:before
	content: $icon-house-hall
.icon-doors:before
	content: $icon-doors
.icon-stock:before
	content: $icon-stock
.icon-date:before
	content: $icon-date
